import i18n, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import cs from '~/services/i18n/cs.json';
import da from '~/services/i18n/da.json';
import de from '~/services/i18n/de.json';
import en from '~/services/i18n/en.json';
import es from '~/services/i18n/es.json';
import fi from '~/services/i18n/fi.json';
import fr from '~/services/i18n/fr.json';
import it from '~/services/i18n/it.json';
import lv from '~/services/i18n/lv.json';
import nl from '~/services/i18n/nl.json';
import no from '~/services/i18n/no.json';
import pl from '~/services/i18n/pl.json';
import sv from '~/services/i18n/sv.json';
import tr from '~/services/i18n/tr.json';
import hu from '~/services/i18n/hu.json';
import lt from '~/services/i18n/lt.json';

const defaultNS = 'translation';

export interface LanguageResource extends ResourceLanguage {
  translation: Record<string, any>;
  languageName: string;
}

export const resources: Record<string, LanguageResource> = {
  cs: {
    translation: cs,
    languageName: 'Czech',
  },
  da: {
    translation: da,
    languageName: 'Danish',
  },
  de: {
    translation: de,
    languageName: 'German',
  },
  en: {
    translation: en,
    languageName: 'English',
  },
  es: {
    translation: es,
    languageName: 'Spanish',
  },
  fi: {
    translation: fi,
    languageName: 'Finnish',
  },
  fr: {
    translation: fr,
    languageName: 'French',
  },
  it: {
    translation: it,
    languageName: 'Italian',
  },
  lv: {
    translation: lv,
    languageName: 'Latvian',
  },
  nl: {
    translation: nl,
    languageName: 'Dutch',
  },
  no: {
    translation: no,
    languageName: 'Norwegian',
  },
  pl: {
    translation: pl,
    languageName: 'Polish',
  },
  sv: {
    translation: sv,
    languageName: 'Swedish',
  },
  tr: {
    translation: tr,
    languageName: 'Turkish',
  },
  hu: {
    translation: hu,
    languageName: 'Hungarian',
  },
  lt: {
    translation: lt,
    languageName: 'Lithuanian',
  },
} as const;

export function configLocales() {
  i18n.use(initReactI18next).init({
    resources,
    ns: defaultNS,
    defaultNS,
    fallbackLng: 'en',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['ol', 'li', 'ul', 'p'],
    },
  });
  return i18n;
}
